import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const SuccessPage = () => (
  <Layout>
    <div className="flex flex-col justify-center h-screen m-auto max-w-xs text-center text-black">
      <Seo title="Stay" />
      <h1 className="pb-2">Thank you!</h1>
      <p className="text-center text-gray-600 mb-12">
        Your RSVP has been submitted.
      </p>
      <Link className="bg-primary-500 w-full p-3 text-white" to="/">
        Go back to the homepage
      </Link>
    </div>
  </Layout>
)

export default SuccessPage
